<template>
  <section class="project-list" v-loading="loading">
    <div class="btn-wrap">
      <el-button size="small" type="primary" @click="addProject">创建</el-button>
    </div>
    <el-table :data="tableData" border ref="table" style="width: 100%">
      <el-table-column  :resizable="false" prop="customerName" align="center" label="项目名称" />
      <el-table-column  :resizable="false" prop="industryName" align="center" label="所属行业" />
      <el-table-column  :resizable="false" prop="createTime" align="center" label="创建时间" width="180px"/>
      <el-table-column  :resizable="false" align="center" label="账户类型">
        <template scope="{ row }">
          <span v-if="row.useType === '1'">正式</span>
          <span v-else-if="row.useType === '0'">试用</span>
        </template>
      </el-table-column>
      <el-table-column  :resizable="false" align="center" label="启用">
        <template scope="{ row }">
          <el-switch v-model="row.isEnable" @change="updateStatus(row.id)" active-color="#13ce66" inactive-color="#ff4949">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column  :resizable="false" label="管理" align="center" width="180px">
        <template scope="{ row }">
          <el-button type="text" @click="editWarning(row.id)">监测主题</el-button>
          <el-button type="text" @click="editUser(row.id)">用户管理</el-button>
        </template>
      </el-table-column>
      <el-table-column  :resizable="false" label="操作" align="center">
        <template scope="{ row }">
          <el-button type="text" @click="editProject(row.id)">修改</el-button>
          <el-button type="text" @click="deleteProject(row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <section class="list-bottom">
      <span class="count">共{{count}}条记录</span>
      <el-pagination @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50]" :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="count">
      </el-pagination>
    </section>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <span class="prompt"><i class="el-icon-warning"></i>确定删除？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="delCus">确 定</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
// import eventHub from '@/utils/eventHub'
import { showAllCus, changeProjectState, del } from '@/api/settings/project'
export default {
  data () {
    return ({
      count: 0,
      tableData: [],
      currentPage: 1,
      size: 10,
      dialogVisible: false,
      cid: 0,
      loading: true
    })
  },
  methods: {
    handleSizeChange (val) {
      this.size = val
      this.showAll()
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.showAll()
    },
    addProject () {
      this.$router.push('/settings/project/add/')
    },
    editProject (id) {
      this.$router.push('/settings/project/edit/' + id)
    },
    deleteProject (id) {
      this.cid = id
      this.dialogVisible = true
    },
    async delCus () {
      const res = await del({ cid: this.cid })
      if (res.code === 0) {
        this.dialogVisible = false
        this.showAll()
        this.$message.success('项目删除成功')
      } else {
        this.$message.error('项目删除失败')
      }
    },
    editUser (id) {
      this.$router.push('/settings/userManagement/editCusUser/' + id)
    },
    editWarning (id) {
      this.$router.push('/settings/theme/show/' + id)
    },
    async showAll () {
      this.loading = true
      const res = await showAllCus({ number: this.currentPage, size: this.size })
      console.log(res)
      this.count = res.data.total
      this.tableData = res.data.list
      this.loading = false
    },
    async updateStatus (id) {
      const res = await changeProjectState({ cid: id })
      if (res.code === 0) {
        this.showAll()
        this.$message.success('状态修改成功')
      } else {
        this.$message.error('状态修改失败')
      }
    }
  },
  mounted () {
    this.showAll()
    // eventHub.$emit('msg', '客户管理')
  }
  // computed: {
  //   // 监听project数据
  //   getProject () {
  //     return this.$store.state.app.project
  //   }
  // },
  // watch: {
  //   //  监听改变事件
  //   getProject: {
  //     handler (newValue, oldValue) {
  //       console.log(newValue)
  //       console.log(oldValue)
  //     }
  //   }
  // }
}
</script>

<style lang="scss">
  .project-list {
    width: 100%;
    min-height: 100%;
    background: #fff;
    border-radius: 2px;
    padding: 20px 20px;
    box-shadow: 0px 1px 13px 0px rgba(0, 0, 0, 0.1);
    position: relative;

    .btn-wrap {
      margin-bottom: 25px;
    }

    .list-bottom {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      /* position: absolute;
      bottom: 20px;
      left: 20px;
      right: 20px; */

      .count {
        line-height: 32px;
      }
    }

    .selected {
      background: rgba(64, 158, 255, .2);
    }

    .el-dialog {
      .prompt {
        height: 28px;
        display: flex;
        align-items: center;

        .el-icon-warning {
          font-size: 28px;
          color: #FF8B35;
          margin-right: 12px;
        }
      }
    }
  }
</style>
